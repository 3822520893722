import React, { Fragment, PureComponent } from 'react'
import { Collapse } from 'react-collapse'
import SettingsLayout from 'components/SettingsLayout/SettingsLayout'
import {
  Button,
  Row,
  Column,
  ComponentWrapper,
  CryptoAddressDetails,
  SectionTitle,
  CryptoAddress,
  Text,
  TooltipIcon,
} from '@stokr/components-library'

import { FlexCenterContainer } from 'components/Settings/Settings.styles'
import user from 'api/user'

import avatarImage from 'static/images/user-profile.png'

import { BigNumber } from 'bignumber.js'

import setRedirectCookie from 'utils/set-redirect-cookie'
import fetchData from 'api/fetch-data'
import { Tooltip } from 'react-tippy'

class SettingsAddresses extends PureComponent {
  state = {
    addresses: [],
    expandedAddressId: null,
  }

  toggleAddress = (addressId) => {
    const { expandedAddressId } = this.state
    this.setState({
      expandedAddressId: addressId !== expandedAddressId ? addressId : null,
    })
  }

  removeAddress = (addressId) => {
    const { addresses } = this.state
    this.setState({
      addresses: addresses.filter((address) => address.id !== addressId),
    })
  }

  async componentDidMount() {
    // try {
    let userObj = await user('get')

    if (userObj.user) {
      this.setState((prevState) => ({
        ...prevState,
        shouldRenderPage: true,
      }))

      const user = userObj.user
      let pool = []

      const ethWallets =
        user?.wallets?.filter((wallet) => wallet.type === 'ethereum') || []

      if (ethWallets.length > 0) {
        const tokenBalances = await fetchData('wallets/tokenBalances')
        let etherPrice = null

        try {
          etherPrice = await fetchData('prices/etherprice')
        } catch (error) {
          console.log('🚀 ~ error', error)
          etherPrice = null
        }

        pool = []
        ethWallets.forEach((wallet, index) => {
          const ethAddress =
            tokenBalances &&
            tokenBalances.find((x) => x.account === wallet.address)

          let data = {}
          if (ethAddress && etherPrice) {
            data = {
              value: new BigNumber(ethAddress.balance).toFixed(5),
              unit: 'ETH',
              eqValue: new BigNumber(ethAddress.balance * etherPrice).toFixed(
                2,
              ),
              eqUnit: '€',
            }
          }
          pool.push({
            id: wallet._id,
            title: wallet.name,
            address: wallet.address,
            data,
            status: '',
          })
        })
      }

      this.setState({ addresses: pool })
    } else {
      window.location.replace('/login')
    }
  }

  render() {
    const { addresses, expandedAddressId, shouldRenderPage } = this.state

    return (
      <>
        {shouldRenderPage && (
          <SettingsLayout title="Settings" activeTab="ethereum-address">
            <ComponentWrapper>
              <Row>
                <Column>
                  <ComponentWrapper noPaddingHorizontal>
                    <SectionTitle>Settings</SectionTitle>
                  </ComponentWrapper>
                  <ComponentWrapper noPadding>
                    <Text>
                      <h3>
                        ETHEREUM Addresses
                        <Tooltip
                          position="top"
                          title={`Registering your Ethereum address is currently disabled
                        until we have a new offer using the Ethereum network.`}
                          theme="light"
                          arrow
                          duration={200}
                        >
                          <TooltipIcon
                            style={{ marginLeft: 10, verticalAlign: 'super' }}
                          />
                        </Tooltip>
                      </h3>
                    </Text>
                  </ComponentWrapper>
                </Column>
              </Row>

              <Row>
                <Column part={9}>
                  <ComponentWrapper noPadding>
                    <FlexCenterContainer
                      noPaddingHorizontal
                      style={{ padding: '16px' }}
                    >
                      {/* <Text>
                        <h5>ADDRESSES MANAGEMENT</h5>
                      </Text>
                      <DataManagementCounter>
                        {addresses.map(address => address).length}
                      </DataManagementCounter>
                      <StatusBadge>verified</StatusBadge> */}
                    </FlexCenterContainer>
                    <Text>
                      <p>
                        From here you can manage all your Ethereum addresses
                        which have been registered with STOKR.
                      </p>
                    </Text>
                  </ComponentWrapper>
                  <ComponentWrapper noPaddingHorizontal>
                    <Button
                      primary
                      onClick={
                        () => {
                          setRedirectCookie()

                          window.location.replace(
                            'https://signup.stokr-staging.de/register-ethereum',
                          )
                        }
                        // onClick={() => navigate("/register-ethereum/flow")
                      }
                      disabled
                    >
                      Add new address
                    </Button>
                  </ComponentWrapper>
                </Column>
              </Row>
            </ComponentWrapper>
            <ComponentWrapper noPadding>
              {addresses.map((address) => (
                <Fragment key={address.id}>
                  <CryptoAddress
                    title={address.title}
                    address={address.address}
                    data={address.data}
                    info={address.info}
                    status={address.status}
                    wrapped
                    borderTop
                  />
                  <Collapse isOpened={expandedAddressId === address.id}>
                    <CryptoAddressDetails
                      data={[
                        {
                          id: 1,
                          avatar: avatarImage,
                          name: 'Robo Sapiens',
                          balance: 'RSN 150',
                          transferClick: () => {},
                        },
                        {
                          id: 2,
                          avatar: avatarImage,
                          name: 'CoolBeanz',
                          balance: 'CBZ 150',
                          transferClick: () => {},
                        },
                        {
                          id: 3,
                          avatar: avatarImage,
                          name: 'Robin Radar',
                          balance: 'RRD 150',
                          transferClick: () => {},
                        },
                      ]}
                    />
                  </Collapse>
                </Fragment>
              ))}
            </ComponentWrapper>
          </SettingsLayout>
        )}
      </>
    )
  }
}

export default SettingsAddresses
